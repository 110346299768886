import React, {useState, Suspense, useEffect} from "react";
import {useNavigate} from 'react-router-dom'
import {
    Box,
    Container,
    Typography
} from "@mui/material";
import logo from '../assets/images/logo.png'
import {DataGrid, gridDateComparator, gridNumberComparator, GridToolbar, itIT} from '@mui/x-data-grid';
import UserService from "../services/user.service";

const Home = () => {
    let navigate = useNavigate()
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const csvOptions = { allColumns: true, utf8WithBom: true }

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        street: false,
        town: false,
        zip_code: false,
        place_of_birth: false,
        date_of_birth: false,
        district: false,
        nationality: false,
        country: false,
        dietary_requirements: false,
        in_date: false,
        out_date: false,
        catering: false,
        agenda_gala: false,
        agenda_lunch: false,
        agenda_preview: false,
        agenda_tour: false,
        transfer_arrival_date: false,
        transfer_arrival_time: false,
        transfer_arrival_from: false,
        transfer_arrival_number: false,
        transfer_arrival_to: false,
        transfer_departure_date: false,
        transfer_departure_from: false,
        transfer_departure_number: false,
        transfer_departure_time: false,
        transfer_departure_to: false,
        transfer_notes: false,
    });

    const columns = [
        {field: 'name', headerName: 'First name', flex: 1},
        {field: 'surname', headerName: 'Last name', flex: 1},
        {field: 'email', headerName: 'Email', flex: 1},
        {field: 'phone', headerName: 'Phone', flex: 1},
        {field: 'company', headerName: 'Company', flex: 1},
        {field: 'role', headerName: 'Role', flex: 1},
        {field: 'catering', headerName: 'Dietary Requirements', flex: 1},
        {field: 'agenda_gala', headerName: 'Gala', flex: 1, valueGetter: (params) => params.row.agenda?.gala},
        {field: 'agenda_lunch', headerName: 'Lunch', flex: 1, valueGetter: (params) => params.row.agenda?.lunch},
        {field: 'agenda_preview', headerName: 'Preview', flex: 1, valueGetter: (params) => params.row.agenda?.preview},
        {field: 'agenda_tour', headerName: 'Tour', flex: 1, valueGetter: (params) => params.row.agenda?.tour},
        {field: 'transfer_arrival_date', headerName: 'Arrival Transfer Date', flex: 1, valueGetter: (params) => params.row.transfer?.arrival?.date},
        {field: 'transfer_arrival_from', headerName: 'Arrival Transfer From', flex: 1, valueGetter: (params) => params.row.transfer?.arrival?.from},
        {field: 'transfer_arrival_number', headerName: 'Arrival Transfer Number', flex: 1, valueGetter: (params) => params.row.transfer?.arrival?.number},
        {field: 'transfer_arrival_time', headerName: 'Arrival Transfer Time', flex: 1,
            valueGetter: function (params) {
                if (params.row.transfer && params.row.transfer.arrival) {
                    return Intl.DateTimeFormat('en', {hour: 'numeric', minute: "numeric", timeZoneName: "short"})
                        .format(new Date(params.row.transfer?.arrival?.time._seconds * 1000))
                } else {
                    return '';
                }
            }
        },
        {field: 'transfer_arrival_to', headerName: 'Arrival Transfer To', flex: 1, valueGetter: (params) => params.row.transfer?.arrival?.to},
        {field: 'transfer_departure_date', headerName: 'Arrival Departure Date', flex: 1, valueGetter: (params) => params.row.transfer?.departure?.date},
        {field: 'transfer_departure_from', headerName: 'Arrival Departure From', flex: 1, valueGetter: (params) => params.row.transfer?.departure?.from},
        {field: 'transfer_departure_number', headerName: 'Arrival Departure Number', flex: 1, valueGetter: (params) => params.row.transfer?.departure?.number},
        {field: 'transfer_departure_time', headerName: 'Arrival Departure Time', flex: 1,
            valueGetter: function (params) {
                if (params.row.transfer && params.row.transfer.departure) {
                    return Intl.DateTimeFormat('en', {hour: 'numeric', minute: "numeric", timeZoneName: "short"})
                        .format(new Date(params.row.transfer?.departure?.time._seconds * 1000))
                } else {
                    return '';
                }
            }
            },
        {field: 'transfer_departure_to', headerName: 'Arrival Departure To', flex: 1, valueGetter: (params) => params.row.transfer?.departure?.to},
        {field: 'transfer_notes', headerName: 'Transfer Notes', flex: 1, valueGetter: (params) => params.row.transfer?.note},
        {field: 'updatedAt', headerName: 'Last update at', flex: 1,
            type: 'dateTime',
            valueGetter: (params) => {
                return params.value ? new Date(params.value?._seconds * 1000) : '';
            },
        },
        /*{
            field: 'complete', headerName: 'Completed', flex: 1,
            valueFormatter: (params) => {
                if (params.value) {
                    return 'Yes';
                } else return 'No';
            },
        },*/
        {
            field: 'approved', headerName: 'Approved', flex: 1,
            valueFormatter: (params) => {
                if (params.value) {
                    return 'Yes';
                } else return 'No';
            },
        },
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        fetchData().then()
    }, [])

    async function fetchData() {
        const response = await UserService.getUsers()
        setUsers(response.data)
        setLoading(false)
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <main>
            <Box>
                <Container maxWidth={false}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{width:'100%', maxWidth:'180px'}}>
                            <img src={logo} width={'100%'} style={{paddingRight:'5rem', margin:'1rem 0'}}/>
                        </div>
                    </div>
                </Container>
            </Box>
            <Container sx={{textAlign: 'center', paddingTop: '2rem'}} maxWidth={false}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 1}}>
                    <Typography variant={"h4"} sx={{textAlign: 'left'}}>Registered Users</Typography>
                </Box>
                <div style={{display: 'flex', height: 670, marginTop: '1rem'}}>
                    <div style={{flexGrow: 1}}>
                        <DataGrid
                            loading={loading}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            disableSelectionOnClick
                            onRowClick={(params, event, details) => {
                                navigate("/user/" + params.row.id);
                                // TODO: Show row details
                            }}
                            componentsProps={{ toolbar: { csvOptions } }}
                            components={{ Toolbar: GridToolbar }}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                            columns={columns}
                            rows={users}
                        />
                    </div>
                </div>
            </Container>
        </main>
    )
}

export default Home
