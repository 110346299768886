import React, {useState, Suspense} from "react";
import {useNavigate} from 'react-router-dom'
import {Button, Card, CircularProgress, Container, Stack, TextField, Typography} from "@mui/material";
import logo from '../assets/images/logo.png'
import authService from "../services/auth.service";

const Login = () => {
    let navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSetEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleSetPassword = (e) => {
        setPassword(e.target.value)
    }

    const handleLogin = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        authService.login(email, password).then(
            () => {
                navigate("/");
                window.location.reload();
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            }
        )
    }

    return(
        <main>
            <Container sx={{textAlign:'center',paddingTop:'2rem'}}>
                <img src={logo} style={{width: '5rem', marginBottom: '5rem', marginTop: '2rem'}}/>
                <Card style={{height:'100%', textAlign:'right'}} variant={'login'}>
                    <Stack direction={"column"} alignItems={"center"} textAlign={'center'}>
                        <Typography variant={'h5'}>Accedi</Typography>
                        <TextField style={{maxWidth:'31.25rem'}} sx={{my:2}} fullWidth placeholder={'Email'} onChange={handleSetEmail}/>
                        <TextField style={{maxWidth:'31.25rem'}} sx={{mb:2}} fullWidth placeholder={'Password'} type={"password"} onChange={handleSetPassword}/>
                        <Button onClick={handleLogin} variant={"contained"} color={"primary"} fullWidth style={{maxWidth:'18.75rem', height:'3rem', fontSize:'1.2rem'}}>Login</Button>
                    </Stack>
                </Card>
            </Container>
        </main>
    )
}

export default Login
