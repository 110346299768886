import axios from "axios";
import authHeader from "./auth-header";
import {API_URL} from "../config";
const getPublicContent = () => {
    return axios.get(API_URL + "all");
};
const getUsers = () => {
    return axios.get(API_URL + "users", { headers: authHeader() })
};
const getUser = (id) => {
    return axios.get(API_URL + "user-data/" + id, { headers: authHeader() })
};

const UserService = {
    getPublicContent,
    getUsers,
    getUser
};

export default UserService;
