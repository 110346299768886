import {CssBaseline, ThemeProvider} from "@mui/material";
import {Routes, Route} from "react-router-dom";
import './App.css';
import {ferrariTheme} from "./theme/ferrariTheme";
import Login from "./pages/Login";
import Home from "./pages/Home";
import User from "./pages/User";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
      <ThemeProvider theme={ferrariTheme}>
          <CssBaseline/>

          <Routes>
              <Route path={'/login'} element={<Login/>}/>
              <Route element={<ProtectedRoute user={localStorage.user}/>}>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/user/:id'} element={<User/>}/>
              </Route>
          </Routes>

      </ThemeProvider>
  );
}

export default App;
