import {createTheme} from "@mui/material";
import { itIT } from '@mui/material/locale';

export const ferrariTheme = createTheme({
    typography: {
        fontFamily: ['Ferrari-Sans']
    },

    components: {
        MuiLinearProgress: {
            styleOverrides:{
                root:{
                    width:'100% !important'
                }
            }
        }
    },

    palette: {
        mode: 'dark',
        primary: {
            main: '#da291c',
        },
    },
    itIT,
});
