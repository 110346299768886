import React, {useState, Suspense, useEffect} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
    Alert,
    Box,
    Button,
    Container,
    FormControl, Snackbar,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import logo from '../assets/images/logo.png'
import UserService from "../services/user.service";
import {ArrowBack, Check, Delete, DeleteForever, Save} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../config";
import authHeader from "../services/auth-header";

const User = () => {
    let navigate = useNavigate()
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [sending, setSending] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false)
    const [openError, setOpenError] = useState(false)
    const [success, setSuccess] = useState('User approved. Sending email with credentials')
    const [error, setError] = useState('An error occurred. Please try again')

    const { id } = useParams()
    const theme = useTheme()

    const [general, setGeneral] = useState([
        {id: 'name', title: 'Name', value: '', compiled: false, required: true},
        {id: 'surname', title: 'Surname', value: '', compiled: false, required: true},
        {id: 'email', title: 'Email', value: '', compiled: false, required: true},
        {id: 'phone', title: 'Phone', value: '', compiled: false, required: true},
        {id: 'company', title: 'Company', value: '', compiled: false, required: true},
        {id: 'role', title: 'Role', value: '', compiled: false, required: true},
    ])

    const [agenda, setAgenda] = useState([
        {id: 'preview', title: 'Private Preview', value: '', compiled: true, required: true},
        {id: 'gala', title: 'Welcome Cocktail & Gala Dinner', value: '', compiled: true, required: true},
        {id: 'lunch', title: 'Lunch', value: '', compiled: true, required: true},
        {id: 'tour', title: 'Plant Tour', value: '', compiled: true, required: true},
    ])

    const [arrival, setArrival] = useState([
        {id: 'from', title: 'From', value: '', compiled: true, required: true},
        {id: 'to', title: 'To', value: '', compiled: true, required: true},
        {id: 'date', title: 'Date', value: '', compiled: true, required: true},
        {id: 'time', title: 'Time', value: '', compiled: true, required: true},
        {id: 'number', title: 'Train/Flight number', value: '', compiled: true, required: true},
    ])

    const [departure, setDeparture] = useState([
        {id: 'from', title: 'From', value: '', compiled: true, required: true},
        {id: 'to', title: 'To', value: '', compiled: true, required: true},
        {id: 'date', title: 'Date', value: '', compiled: true, required: true},
        {id: 'time', title: 'Time', value: '', compiled: true, required: true},
        {id: 'number', title: 'Train/Flight number', value: '', compiled: true, required: true},
    ])

    function handleEdit (event, key, c) {
        console.log('event');
        console.log(event);
        console.log('key');
        console.log(key);
        console.log('c');
        console.log(c);
    }

    useEffect(() => {
        fetchData(id).then()
    }, [id])

    async function fetchData(id) {
        const response = await UserService.getUser(id)
        setUser(response.data)
        general.map(d => d.value = response.data[d.id])
        setGeneral([...general])
        if (response.data.agenda) {
            const agendaData = response.data.agenda;
            if (agendaData['lunch']) {
                agendaData['lunch'] = (agendaData['lunch'] === 'box' ? 'Box Lunch' : 'Lunch')
            }
            agenda.map(d => d.value = agendaData[d.id] ?? '')
            setAgenda([...agenda])
        }
        if (response.data.transfer && response.data.transfer.arrival) {
            const arrivalData = response.data.transfer.arrival;
            if (arrivalData['time']) {
                const time = new Date(arrivalData['time']._seconds * 1000);
                arrivalData['time'] = Intl.DateTimeFormat('en', { hour: 'numeric', minute: "numeric", timeZoneName: "short" }).format(time);
            }
            arrival.map(d => d.value = arrivalData[d.id] ?? '')
            setArrival([...arrival])
        }
        if (response.data.transfer && response.data.transfer.departure) {
            const departureData = response.data.transfer.departure;
            if (departureData['time']) {
                const time = new Date(departureData['time']._seconds * 1000);
                departureData['time'] = Intl.DateTimeFormat('en', { hour: 'numeric', minute: "numeric", timeZoneName: "short" }).format(time);
            }
            departure.map(d => d.value = departureData[d.id] ?? '')
            setDeparture([...departure])
        }

        setLoading(false)
    }

    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccess(false);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenError(false);
    };

    async function changeApproval(approved) {
        setSending(true);
        console.log(approved);
        const data = {
            approved: approved,
        }
        try {
            await axios.post(API_URL + 'user-data/' + id, data, {headers: authHeader()})
            user.approved = approved;
            if (approved) {
                setSuccess('User approved. Sending email with credentials')
                setOpenSuccess(true)
            } else {
                setSuccess('Removed approval, user won\'t be able to login anymore')
                setOpenSuccess(true)
            }
        } catch (e) {
            setOpenError(true)
        }
        setSending(false);
    }

    return (
        <main>
            <Box>
                <Container maxWidth={false}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{width:'100%', maxWidth:'180px'}}>
                            <img src={logo} width={'100%'} style={{paddingRight:'5rem', margin:'1rem 0'}}/>
                        </div>
                    </div>
                </Container>
            </Box>
            <Container sx={{paddingTop: '2rem'}}>
                <Button LinkComponent={Link} to={"/"} variant="outlined" sx={{marginBottom: '2rem'}} startIcon={<ArrowBack />}>Back</Button>
                <Typography variant={"h4"} sx={{textAlign: 'left'}}>{user?.name} {user?.surname}</Typography>
                <Typography variant={"h5"} sx={{textAlign: 'left', margin: '1rem 0'}}>General Info</Typography>
                <Box component={"form"} sx={{
                    '& > :not(style)': {p: 1},
                }}>
                    {general.map(g =>
                        <FormControl key={g.id} sx={{
                            width: '50%',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                            }, marginTop: '0.3rem'
                        }}>
                            <TextField label={g.title} variant={"standard"} disabled={g.compiled} required={g.required} id="name-input" value={g.value}
                                       onChange={(e) => handleEdit(e, g.id)}/>
                        </FormControl>
                    )}
                </Box>

                {user && user.approved ? <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <Button size={"large"} variant="outlined"
                                disabled={!user || sending} onClick={() => changeApproval(false)}
                                sx={{margin: '2rem 0'}} startIcon={<DeleteForever />}>Remove Approval</Button>
                    </Box>
                    : <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <Button size={"large"} variant="contained"
                                disabled={!user || sending} onClick={() => changeApproval(true)}
                                sx={{margin: '2rem 0'}} startIcon={<Check />}>Approve</Button>
                    </Box>}

                <Typography variant={"h5"} sx={{textAlign: 'left', margin: '2rem 0'}}>Agenda</Typography>
                <Box component={"form"} sx={{
                    '& > :not(style)': {p: 1},
                }}>
                    {agenda.map(g =>
                        <FormControl key={g.id} sx={{
                            width: '50%',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                            }, marginTop: '0.3rem'
                        }}>
                            <TextField label={g.title} variant={"standard"} InputProps={{readOnly: true,}}
                                       required={g.required} id="name-input" value={g.value} />
                        </FormControl>
                    )}
                </Box>

                <Typography variant={"h5"} sx={{textAlign: 'left', margin: '2rem 0'}}>Travel Info - Arrival</Typography>
                <Box component={"form"} sx={{
                    '& > :not(style)': {p: 1},
                }}>
                    {arrival.map(g =>
                        <FormControl key={g.id} sx={{
                            width: '50%',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                            }, marginTop: '0.3rem'
                        }}>
                            <TextField label={g.title} variant={"standard"} InputProps={{readOnly: true,}}
                                       required={g.required} id="name-input" value={g.value} />
                        </FormControl>
                    )}
                </Box>

                <Typography variant={"h5"} sx={{textAlign: 'left', margin: '2rem 0'}}>Travel Info - Departure</Typography>
                <Box component={"form"} sx={{
                    '& > :not(style)': {p: 1},
                }}>
                    {departure.map(g =>
                        <FormControl key={g.id} sx={{
                            width: '50%',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                            }, marginTop: '0.3rem'
                        }}>
                            <TextField label={g.title} variant={"standard"} InputProps={{readOnly: true,}}
                                       required={g.required} id="name-input" value={g.value} />
                        </FormControl>
                    )}
                </Box>
            </Container>
            <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
                    {success}
                </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </main>
    )
}

export default User
